@import "styles/color";

.scraping-detail-image-page {
    .image-wrapper {
        position: relative;
    }

    .target-mark {
        position: absolute;
        left: 40px;
        height: 280px;
        border: 5px solid $red-600;
        background-color: transparent;
    }
}
