$white: #ffffff;
$black: #0c2c44;

$gray-900: #212121;
$gray-800: #424242;
$gray-700: #616161;
$gray-600: #757575;
$gray-500: #9e9e9e;
$gray-400: #bdbdbd;
$gray-300: #e0e0e0;
$gray-200: #eeeeee;
$gray-100: #f5f5f5;
$gray-50: #fafafa;

$green-100: #c8e6c9;
$green-200: #a5d6a7;
$green-500: #4caf50;
$green-800: #2e7d32;

$deep-purple-100: #d1c4e9;
$deep-purple-200: #b39ddb;
$deep-purple-400: #7E57C2;
$deep-purple-500: #673ab7;
$deep-purple-800: #4527a0;
$deep-purple-900: #311B92;

$red-500: #f44336;
$red-600: #e53935;

$blue-600: #1E88E5;
$blue-700: #1976D2;
