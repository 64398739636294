@import "styles/color";

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7%;

    .title-area {
        margin-bottom: 4rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 25rem;

        h1 {
            font-size: 3rem;
            font-weight: bold;
            color: $gray-800;
        }

        p {
            font-size: 1rem;
            color: $gray-700;
        }
    }

    .login-btn,
    .register-btn {
        width: 25rem;
        height: 3rem;
        font-weight: bold;
        font-size: 1rem;
    }

    .login-btn {
        margin-top: 1rem;
        background-color: $deep-purple-800;

        &:hover {
            background-color: $deep-purple-900;
        }
    }

    .register-btn {
        margin-top: 0.5rem;
        background-color: $deep-purple-100;
        color: $deep-purple-500;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .move-to-landing {
        margin-top: 2rem;

        border-bottom: 1px solid $gray-800;
        text-align: center;
        font-size: 1.1rem;
        color: $gray-800;

        &:hover {
            color: $deep-purple-800;
        }
    }

    form {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
            width: 25rem;
            height: 3rem;
            border: none;
            border-bottom: 1px solid $gray-400;
            border-radius: 0;
            color: $black;
            font-size: 1rem;

            &:focus {
                border-color: $black;
            }
        }
    }
}
