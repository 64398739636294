@import "styles/color";

.main-page {
    padding-top: 3rem;
    padding-left: 2rem;

    $table-border-color: $gray-400;

    .table-wrapper {
        margin-top: 5rem;

        width: 62rem;
        border: 1px solid $table-border-color;
    }

    .table-header,
    .table-body-item {
        display: flex;

        .keyword {
            flex: 0.5;
            padding: 0.4rem;
        }

        .blog-list {
            flex: 2;
            padding: 0.4rem;
        }

        .detail {
            flex: 0.4;
            padding: 0.4rem;
        }

        .log-area {
            flex: 2;
            padding: 0.4rem;
        }

        li + li {
            border-left: 1px solid $table-border-color;
        }
    }

    .table-header {
        border-bottom: 1px solid $table-border-color;
        font-weight: 500;
    }

    .table-body-item + .table-body-item {
        border-top: 1px solid $table-border-color;
    }

    .table-body-item {
        .keyword {
            font-weight: bold;

            a {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .more-btn {
            color: $green-800;

            &:hover {
                text-decoration: underline;
            }
        }

        .blog-list {
            display: flex;
            flex-direction: column;

            a {
                word-break: break-all;
                color: $blue-700;
                text-decoration: underline;

                &:visited {
                    color: $deep-purple-800;
                }
                // width: 15rem;
                // overflow: hidden;
                // white-space: nowrap;
                // text-overflow: ellipsis;
            }
        }
    }
}
