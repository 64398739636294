@import "styles/color";

#product-page {
    section {
        .inner-wrapper {
            margin: 0 auto;
            padding: 3rem 0;

            width: 1200px;
        }
    }

    .main-title {
        background-color: $deep-purple-200;

        position: relative;
        height: 40rem;

        .text-contents {
            position: relative;
            z-index: 10;
            padding-top: 4rem;

            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 100%;
            color: $white;
        }

        h1 {
            font-size: 5rem;
        }

        p {
            margin-top: 1rem;
            font-size: 2rem;
        }

        .move-to-signup-page {
            margin-top: 3rem;
            padding: 0.7rem 2rem;
            border-radius: 30px;
            background-color: $deep-purple-400;
            font-size: 1.2rem;
            cursor: pointer;

            &:hover {
                background-color: $deep-purple-500;
                font-weight: bold;
            }
        }

        .circles {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-color: red;

            .circle {
                position: absolute;
                border-radius: 50%;
                border: 1px solid rgba($color: $white, $alpha: 0.4);
                top: 50%;
                left: 50%;

                &:nth-child(1) {
                    width: 50rem;
                    height: 25rem;
                    transform: translate(-50%, -50%) rotate(15deg);
                }

                &:nth-child(2) {
                    width: 50rem;
                    height: 25rem;
                    transform: translate(-50%, -50%) rotate(150deg);
                }

                &:nth-child(3) {
                    width: 50rem;
                    height: 25rem;
                    transform: translate(-50%, -50%) rotate(168deg);
                }
            }
        }
    }

    .intro {
        text-align: left;
        padding-top: 5rem;
        padding-bottom: 5rem;

        h3 {
            margin-bottom: 1rem;
            font-size: 3rem;
            font-weight: normal;
        }

        p {
            word-break: keep-all;
            line-height: 1.65;
            font-size: 1.2rem;
        }
    }

    .service-features {
        background-color: $deep-purple-100;
        color: $white;

        h3 {
            margin-bottom: 1.5rem;
            font-weight: normal;
            font-size: 3rem;
        }

        h4 {
            font-size: 1.3rem;
        }

        img {
            width: 37rem;
        }

        ul > li {
            display: flex;
            justify-content: space-between;
        }

        li + li {
            margin-top: 2rem;
        }
    }

    .start-now {
        padding: 3rem 0 5rem;

        color: $gray-100;
        background-color: $deep-purple-500;
        display: flex;
        align-items: center;
        flex-direction: column;

        h2 {
            margin-bottom: 1rem;
        }

        a {
            text-decoration: underline;
        }
    }
}
