@import "styles/color";

.layout {
    display: flex;
    min-height: 100vh;

    #side-nav {
        flex-basis: 250px;
        flex-shrink: 0;
        border-right: 1px solid $gray-300;
    }

    main {
        flex-grow: 1;
    }
}

#side-nav {
    h1 {
        margin-top: 3rem;
        padding-left: 10px;
        color: $gray-900;
        font-size: 2rem;
    }

    ul {
        padding-top: 2rem;
    }

    li {
        a {
            width: 100%;

            &.active {
                * {
                    color: $deep-purple-800;
                    text-decoration: underline;
                }
            }
        }
    }
}
